<template>
  <div class="contain-sss">
    <!--比赛封面-->
    <div class="contain-banner" v-if="id === 1">
      <img :src="eventInfo.eventCover" alt="">
    </div>
    <!--排名信息-->
    <div class="contain-swiper" v-if="id === 3">
      <div class="swiper-header">选手排名</div>
      <div class="swiper-center">
        <div class="content-student" id="scrollAreaTab">
        <el-table
            :data="rankingList"
            style="width: 100%;background-color: transparent">
          <el-table-column
              prop="date"
              label="排名"
              align="center"
          >
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="name"
              label="参赛选手"
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="finalScore"
              label="最终得分">
          </el-table-column>
        </el-table>
      </div>
      </div>

    </div>
    <!--选手顺序-->
    <div class="contain-swiper" v-if="id === 4">
      <div class="swiper-header">参赛选手选号</div>
      <div class="swiper-center">
        <div class="content-student" id="scrollArea">
          <div class="student-box" v-for="(item,index) in playerList" :key="item.id">
            <div v-if="item.avatarUrl" class="paiming-box">
              <img :src="item.avatarUrl">
              <i style="color: var(--theme_font-color);;font-size:var(--theme_font-size);"> {{ item.name }}</i>
            </div>

            <span v-if="!item.avatarUrl" class="active">
              <i style="font-size:  var(--theme_font-size);font-weight: bold">{{ index + 1 }}</i>
              <i style="color: var(--theme_font-color);font-size:  var(--theme_font-size);"> {{ item.name }}</i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--打分信息  1----4-->
    <div class="contain-swiper" v-if="id === 5 && (eventInfo.scoreStandard === 1 || eventInfo.scoreStandard===4) ">
      <div class="swiper-header">选手最终得分</div>
      <div class="swiper-center">
        <div class="result-box">
          <div class="result-line" v-show="eventInfo.scoreStandard === 1||eventInfo.scoreStandard===4">{{ currentInfo.name }}</div>

          <div class="result-line" v-show=" currentInfo.specialSubPoints">
            {{ currentInfo.specialSubPoints > 0 ? "裁判长加分" : "裁判长减分" }}:
            {{ currentInfo.specialSubPoints === null ? 0 : currentInfo.specialSubPoints }}
          </div>
          <div class="result-line" style="border-bottom: none;font-size:  var(--theme_font-size);">最终成绩:
            {{ currentInfo.finalScore ? (currentInfo.finalScore + '分') : '评分未结束' }}
          </div>

          <div class="result-line" v-for="item in judgeList" :key="item.id"
               v-show="eventInfo.scoreStandard === 1||eventInfo.scoreStandard===4" >
            <span> {{ item.name }} 评委</span>
            <span v-if="item.eventJudgeScore">
                           {{
                item.eventJudgeScore.scoreStandard === 1 || item.eventJudgeScore.scoreStandard === 4 ? item.eventJudgeScore.score : item.eventJudgeScore.groupStyle === 1 ? item.eventJudgeScore.scoreA : item.eventJudgeScore.scoreB
              }}
                       </span>
            <span v-if="!item.eventJudgeScore">
                       未评分
            </span>
          </div>

        </div>
      </div>
    </div>
    <!--打分信息  2----3-->
    <div class="contain-swiper" v-if="id === 5 && (eventInfo.scoreStandard === 2 || eventInfo.scoreStandard===3)">
      <div class="swiper-header">选手最终得分</div>
      <div class="swiper-center">
        <div class="result-box">
          <div class="result-header" v-show="eventInfo.scoreStandard === 2 || eventInfo.scoreStandard===3">{{ currentInfo.name }}</div>
          <div class="result-btm"  v-show="eventInfo.scoreStandard === 2||eventInfo.scoreStandard===3">
                       <span style="margin-right: 20px"> {{ currentInfo.specialSubPoints > 0 ? "裁判长加分" : "裁判长减分" }}:
                        {{ currentInfo.specialSubPoints === null ? 0 : currentInfo.specialSubPoints }}</span>
          </div>

          <div class="result-btm" style="margin-top: 30px" v-show="eventInfo.scoreStandard === 2||eventInfo.scoreStandard===3">
<!--           <span style="margin-right: 20px"> {{ currentInfo.specialSubPoints > 0 ? "裁判长加分" : "裁判长减分" }}:-->
<!--            {{ currentInfo.specialSubPoints === null ? 0 : currentInfo.specialSubPoints }}</span>-->
            <span>最终成绩</span>:{{ currentInfo.finalScore ? (currentInfo.finalScore + '分') : '评分未结束' }}
          </div>

          <div class="result-table">
            <div class="table-box" >
              <div class="table-box-header">A组评委</div>
              <div   class="result-line-table" v-for="item in Alist" :key="item.id" >
                <div>
                  <span> {{ item.name }} 评委</span>
                  <span v-if="item.eventJudgeScore">
                           {{
                item.eventJudgeScore.scoreStandard === 1 || item.eventJudgeScore.scoreStandard === 4 ? item.eventJudgeScore.score : item.eventJudgeScore.groupStyle === 1 ? item.eventJudgeScore.scoreA : item.eventJudgeScore.scoreB
                  }}
                           </span>
                      <span v-if="!item.eventJudgeScore">
                           未评分
                </span>
                </div>
              </div>
            </div>
            <div class="table-box" >
              <div class="table-box-header">B组评委</div>
              <div class="result-line-table"  v-for="item in Blist" :key="item.id" >
                <div>
                  <span> {{ item.name }} 评委</span>
                  <span v-if="item.eventJudgeScore">
                           {{
                item.eventJudgeScore.scoreStandard === 1 || item.eventJudgeScore.scoreStandard === 4 ? item.eventJudgeScore.score : item.eventJudgeScore.groupStyle === 1 ? item.eventJudgeScore.scoreA : item.eventJudgeScore.scoreB
              }}
                       </span>
                  <span v-if="!item.eventJudgeScore">
                       未评分
            </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--        //选手信息-->
    <div class="contain-swiper" v-if="id === 2">
      <div class="swiper-header">选手介绍</div>
      <div class="swiper-center">
        <img class="image" v-show="playerInfo.avatarUrl" :src="playerInfo.avatarUrl">
        <div class="center-title" >{{ playerInfo.name }} 简介</div>
        <div class="center-body">
          <div class="body-text">
            {{ playerInfo.descContent }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {findById, getRanking, getPlayerList2, getCurrentPlayerInfo, getPlayerInfo} from "../../api/event";
import request from "../../utils/request";

export default {
  name: "eventShow",
  data() {
    return {
      id: null,
      roleFlag: null,
      eventInfo: null,
      rankingList: [],
      playerList: [],
      currentInfo: [],
      judgeList: [],
      Alist:[],
      Blist:[],
      playerInfo: {},
      scoreUrl: null,
      type:null
    }
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log("连接websocket成功");
    },
    // 接收消息
    onmessage(e) {
      this.getEventInfo(this.eventInfo.id)
    },
    // 连接报错
    onerror() {
      console.log("连接websocket出错");
    },
    // 关闭连接
    onclose() {
      console.log("websocket关闭");
    },
  },
  created() {
    this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
    this.getEventInfo(this.eventInfo.id)

  },
  mounted() {
    //1管理 2评委 3主持 4大屏端
    this.roleFlag = sessionStorage.getItem('roleFlag');

  },
  methods: {
    //查询比赛信息
    async getEventInfo(id) {
      const {data} = await findById(id);
      if (data.code === 200) {
        this.eventInfo = data.data
        this.id = data.data.startShow
        if (this.id === 3) {
          //选手排名信息
          getRanking(this.eventInfo.id).then(res => {
            if (res.data.code === 200) {
              this.rankingList = res.data.data
              console.log('  this.rankingList', this.rankingList)
            }
          })
          let scrollArea
          const time = setTimeout(() => {
            scrollArea = document.getElementById("scrollAreaTab");
            setInterval(function () {
              // 每次滚动的距离，可以根据需求进行调整
              scrollArea.scrollTop += 3;
              const scrollTop = scrollArea.scrollTop; // 滚动条顶部到可见区域顶部的距离
              const offsetHeight = scrollArea.offsetHeight; // 可见区域的高度
              const scrollHeight = scrollArea.scrollHeight; // 盒子总高度
              if (scrollTop + offsetHeight >= scrollHeight) {
                setTimeout(()=>{
                  scrollArea.scrollTop = 0;
                  clearTimeout(time);
                },500)
              }
            }, 100);
          }, 500)
        } else if (this.id === 4) {
          //选手顺序
          getPlayerList2(this.eventInfo.id).then(res => {
            if (res.data.code === 200) {
              this.playerList = res.data.data
            }
          })
          let scrollArea
          setTimeout(() => {
            scrollArea = document.getElementById("scrollArea");
            setInterval(function () {
              // 每次滚动的距离，可以根据需求进行调整
              scrollArea.scrollTop += 3;
              const scrollTop = scrollArea.scrollTop; // 滚动条顶部到可见区域顶部的距离
              const offsetHeight = scrollArea.offsetHeight; // 可见区域的高度
              const scrollHeight = scrollArea.scrollHeight; // 盒子总高度
              if (scrollTop + offsetHeight >= scrollHeight) {
                scrollArea.scrollTop = 0;
              }
            }, 100);
          }, 500)
        } else if (this.id === 5) {
          //当前选手分数
          getCurrentPlayerInfo(this.eventInfo.id).then(res => {
            if (res.data.code === 200) {
              this.currentInfo = res.data.data.currentInfo
              this.judgeList = res.data.data.judgeList
              this.Alist = this.judgeList.filter(item => {
                if(item.eventJudgeScore){
                  return item.eventJudgeScore.groupStyle === 1
                }
              })
              this.Blist = this.judgeList.filter(item => {
                if(item.eventJudgeScore){
                  return item.eventJudgeScore.groupStyle === 2
                }
              })
              console.log('pppp', this.currentInfo)

            }
          })
        } else if (this.id === 2) {
          //选手介绍
          getPlayerInfo(this.eventInfo.id).then(res => {
            if (res.data.code === 200) {
              this.playerInfo = res.data.data
              request({
                url: '/personnel/scoreQrCode?personnelId=' + this.playerInfo.id,
                method: 'get',
                responseType: 'blob',
              }).then(resp => {
                const blob = new Blob([resp.data], {type: "application/vnd.ms-excel"});
                const url = window.URL.createObjectURL(blob);
                this.scoreUrl = url
                // let a = document.createElement('a');
                //a.download = '学生考核成绩表.xls';
                // a.href = url;
                // a.click();
              })
            }
          })
        }
        if (this.roleFlag === '3') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/hostPage') {
            this.$router.push('/hostPage')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawControl') {
            this.$router.push('/drawControl')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventControl') {
            this.$router.push('/eventControl')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/eventOver') {
            this.$router.push('/eventOver')
          }
        }
        if (this.roleFlag === '4') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/beforeShow') {
            this.$router.push('/beforeShow')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawSort') {
            this.$router.push('/drawSort')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventShow') {
            this.$router.push('/eventShow')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/showOver') {
            this.$router.push('/showOver')
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.contain-sss {
  .contain-banner {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  width: 100%;
  height: 100%;

  .contain-left {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 50px;
    background: url("../../assets/images/left.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    cursor: pointer;
  }

  .contain-right {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 50px;
    background: url("../../assets/images/right.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    cursor: pointer;

  }

  .contain-swiper {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat  no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    background-color: var(--theme_bg-color);
    background-image:var(--theme_bg-img) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .swiper-header {
      width: 40%;
      height: 80px;
      box-sizing: border-box;
      margin-bottom: 30px;
      background: url("../../assets/images/new1.png") no-repeat center;
      background-size: contain;
      text-align: center;
      line-height: 80px;
      color: var(--theme_font-color);
      font-size: 40px;
      font-weight: bold;
    }

    .swiper-center {
      position: relative;
      width: 80%;
      height: calc(100% - 200px);
      background: url("../../assets/images/new2.png") no-repeat;
      background-size: 100% 100%;
      padding: 3%;
      overflow: auto;
      /deep/.el-table .cell {
        line-height: normal;
      }
      /deep/ .el-table th.el-table__cell {
        background-color: transparent;
      }
      /deep/.el-table th.el-table__cell>.cell{
        font-size: 30px;
      }
      /deep/.el-table td.el-table__cell>.cell{
        font-size: 30px;
      }

      /deep/ .el-table tr {
        background-color: transparent;
        color: var(--theme_font-color);
      }

      .image {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }

      .center-title {
        width: 100%;
        color: var(--theme_font-color);
        height: 100px;
        font-size:  var(--theme_font-size);
        line-height: 100px;
        font-family: "楷体";
        font-weight: bold;
      }

      .content-student {
        width: 100%;
        height: calc(100% - 100px);
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .student-box {
          width: 200px;
          height: 200px;
          margin: 0 0 40px 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .paiming-box {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            img {
              width: 50%;
              height: 50%;
              border-radius: 50%;
            }
          }

          .active {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            font-size: 20px;
            color: #f7f01b;
            font-family: "楷体";
          }

          .text-box {
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: var(--theme_font-color);
            font-family: "楷体";
          }
        }
      }

      .center-body {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;

        justify-content: space-around;

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
        }

        .body-text {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          color: var(--theme_font-color);
          font-size:  var(--theme_font-size);
          font-family: "楷体";
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            margin-bottom: 20px;
          }
        }
      }

      .result-box {
        width: 100%;
        height: 100%;
        /*background-color: red;*/
        overflow: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .result-header{
          width: 100%;
          height: 10%;
          text-align: center;
          font-size:  var(--theme_font-size);
          white-space: nowrap;
          border-bottom: 3px solid;
          border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;
          color: var(--theme_font-color);
        }
        .result-table{
          width: 100%;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .table-box{
            width: 40%;
            height: 100%;
            border-right: 3px solid;
            border-left: 3px solid;
            border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;;
            .table-box-header{
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 3px solid;
              border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;;
              color: var(--theme_font-color);
              font-size:  var(--theme_font-size);
            }
            .result-line-table {
              color: var(--theme_font-color);
              width: 100%;
              height: 80px;
              margin-top: 50px;
              border-bottom: 3px solid;
              display: flex;
              align-items: center;
              justify-content: space-around;
              padding: 2% 10%;
              box-sizing: border-box;
              font-size:  var(--theme_font-size);
              white-space: nowrap;
              border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;;
            }
          }
        }
        .result-btm{
          width: 100%;
          height: 10%;
          border-top: 3px solid;
          border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;
          color: var(--theme_font-color);
          text-align: center;
          font-size:  var(--theme_font-size);
        }

        .result-line {
          color: var(--theme_font-color);
          width: 40%;
          height: 80px;
          margin-top: 50px;
          border-bottom: 3px solid;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 2% 10%;
          box-sizing: border-box;
          font-size:  var(--theme_font-size);
          white-space: nowrap;
          border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;;
        }
      }

      .box-player {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .player-box {
          margin: 20px 0;

          .active {
            width: 100px;
            height: 100px;
            background: #cccccc no-repeat;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .child {
              color: yellow;
              text-shadow: 5px 3px 3px rgba(0, 0, 0, 0.85);
              font-size: 35px;
              font-weight: bold;
            }
          }
        }
      }

      .jian-box {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .jian-left {
          width: 10px;
          height: 100%;
          cursor: pointer;
          background: url("../../assets/images/left.png") no-repeat;
          background-size: 100% 100%;
          background-attachment: fixed;
        }

        .jian-right {
          cursor: pointer;
          width: 10px;
          height: 100%;
          background: url("../../assets/images/right.png") no-repeat;
          background-size: 100% 100%;
          background-attachment: fixed;
        }
      }
    }

    .contain-left {
      position: absolute;
      left: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 50px;
      background: url("../../assets/images/left.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      cursor: pointer;
    }

    .contain-right {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 50px;
      background: url("../../assets/images/right.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      cursor: pointer;

    }

    .swiper-box {
      width: 80%;
      height: 60%;
      /*padding: 50px 150px;*/
      box-sizing: border-box;
      background: url("../../assets/images/borderBanner.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .box-player {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .player-box {
          width: 20%;
          margin: 20px 0;
          height: 100px;
        }
      }

      .box-title {
        width: 100%;
        color: var(--theme_font-color);
        font-size: 18px;
        text-align: center;
      }

      .box-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--theme_font-color);
      }
    }
  }
}
</style>
